import React from 'react';
import { Mail, BrandLinkedin, BrandGithub, Movie } from 'tabler-icons-react';

// Import local icons
import BrandPython from '../icons/tabler-brand-python.svg';
import BrandReact from '../icons/tabler-brand-react.svg';
import BrandHtml5 from '../icons/brand-html5.svg';
import BrandTailwind from '../icons/brand-tailwind.svg';
import BrandAWS from '../icons/brand-aws.svg';
import PostgresqlLogo from '../icons/postgresql-logo.svg';
import TablerSQL from '../icons/tabler-icons-sql.svg';
import TablerSwift from '../icons/tabler-swift.svg';
import FlaskLogo from '../icons/flask-logo.svg';
import BrandGit from '../icons/brand-git.svg';
import JestLogo from '../icons/jest-logo.svg';
import BrandJavaScript from '../icons/brand-javascript.svg';

const Landing = () => {
  return (
    <div className="font-sans bg-[#285DEF]">
      {/* Profile Section */}
      <section className="bg-[#262333] p-4 sm:p-8 md:p-10 lg:p-16 xl:p-24">
        <div className="container mx-auto px-4 xl:px-8 2xl:px-16 flex flex-wrap md:flex-nowrap items-center">
          <div className="w-full md:w-1/3 mb-8 md:mb-0 flex flex-col items-center">
            <div className="relative mb-8 transform rotate-3">
              <div 
                className="w-48 h-48 sm:w-64 sm:h-64 lg:w-80 lg:h-80 overflow-hidden"
                style={{
                  clipPath: 'polygon(5% 20%, 95% 5%, 90% 95%, 10% 90%)',
                  backgroundColor: '#285DEF'
                }}
              >
                <img 
                  src="/images/profile.jpg" 
                  alt="Max Ihnen" 
                  className="w-full h-full object-cover transform scale-90 rotate-3"
                />
              </div>
            </div>
            <div className="bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 rounded-full px-6 py-4 flex space-x-6">
              <a href="https://www.linkedin.com/in/ihnen/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200 transition-colors">
                <BrandLinkedin size={32} className="sm:w-8 sm:h-8" />
              </a>
              <a href="mailto:maxihnen@gmail.com" className="text-white hover:text-gray-200 transition-colors">
                <Mail size={32} className="sm:w-8 sm:h-8" />
              </a>
              <a href="https://github.com/maxdenuevo" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200 transition-colors">
                <BrandGithub size={32} className="sm:w-8 sm:h-8" />
              </a>
              <a href="https://letterboxd.com/cinenauta/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-200 transition-colors">
                <Movie size={32} className="sm:w-8 sm:h-8" />
              </a>
            </div>
          </div>
          <div className="w-full md:w-2/3 flex items-center justify-center md:justify-end">
            <pre className="text-left text-lg sm:text-xl md:text-2xl bg-gray-900 p-6 sm:p-8 rounded-lg w-full md:max-w-lg">
              <code>
                <span className="text-gray-400">{'{'}</span>
                <br />
                <span className="text-emerald-400">"name"</span>
                <span className="text-gray-400">:</span>
                <span className="text-orange-400"> "max"</span>
                <span className="text-gray-400">,</span>
                <br />
                <span className="text-emerald-400">"occupation"</span>
                <span className="text-gray-400">:</span>
                <span className="text-orange-400"> "full stack dev"</span>
                <span className="text-gray-400">,</span>
                <br />
                <span className="text-emerald-400">"likes"</span>
                <span className="text-gray-400">:</span>
                <span className="text-orange-400"> ["movies", "tacos"]</span>
                <br />
                <span className="text-gray-400">{'}'}</span>
              </code>
            </pre>
          </div>
        </div>
      </section>

      {/* Skillset Section */}
      <section className="bg-[#285DEF] p-4 sm:p-8 md:p-10 lg:p-16 xl:p-24">
        <div className="container mx-auto px-4 xl:px-8 2xl:px-16">
          <h2 className="text-black text-5xl sm:text-6xl font-black mb-12">Skillset</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 sm:gap-6 lg:gap-8">
            {[
              { name: 'Python', icon: BrandPython },
              { name: 'Flask', icon: FlaskLogo },
              { name: 'SQL', icon: TablerSQL },
              { name: 'PostgreSQL', icon: PostgresqlLogo },
              { name: 'AWS', icon: BrandAWS },
              { name: 'Git', icon: BrandGit },
              { name: 'Jest', icon: JestLogo },
              { name: 'JavaScript', icon: BrandJavaScript },
              { name: 'React', icon: BrandReact },
              { name: 'Tailwind', icon: BrandTailwind },
              { name: 'HTML+CSS', icon: BrandHtml5 },
              { name: 'Swift', icon: TablerSwift },
            ].map((skill, index) => (
              <div key={index} className="bg-white p-3 sm:p-4 lg:p-6 rounded-lg flex flex-col items-center justify-center aspect-square hover:shadow-lg transition-shadow">
                <img
                  src={skill.icon}
                  alt={skill.name}
                  className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24"
                />
                <span className="mt-2 sm:mt-4 text-black text-center font-bold text-xs sm:text-sm lg:text-base">{skill.name}</span>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;