import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Mail, BrandLinkedin, BrandGithub, Movie } from 'tabler-icons-react';
import Landing from './components/Landing';
import Portfolio from './components/Portfolio';
import CV from './components/CV';

function App() {
  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        <nav className="bg-[#285DEF] p-4 sm:p-5 md:p-5 lg:p-6">
          <div className="container mx-auto flex justify-between items-center px-4 xl:px-8 2xl:px-16">
            <Link to="/" className="text-black text-6xl sm:text-7xl lg:text-8xl font-black">Max Ihnen</Link>
            <div className="flex space-x-6">
              <Link to="/" className="text-black font-bold text-lg sm:text-xl">Skillset</Link>
              <Link to="/portfolio" className="text-black font-bold text-lg sm:text-xl">Portfolio</Link>
              <Link to="/cv" className="text-black font-bold text-lg sm:text-xl">CV</Link>
            </div>
          </div>
        </nav>

        <main className="flex-grow bg-[#285DEF]">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/cv" element={<CV />} />
          </Routes>
        </main>

        <footer className="bg-[#262333] text-white p-4">
          <div className="container mx-auto flex flex-col items-center">
            <div className="flex justify-center space-x-4 mb-2">
              <a href="https://www.linkedin.com/in/ihnen/" target="_blank" rel="noopener noreferrer">
                <BrandLinkedin size={24} />
              </a>
              <a href="mailto:maxihnen@gmail.com">
                <Mail size={24} />
              </a>
              <a href="https://github.com/maxdenuevo" target="_blank" rel="noopener noreferrer">
                <BrandGithub size={24} />
              </a>
              <a href="https://letterboxd.com/cinenauta/" target="_blank" rel="noopener noreferrer">
                <Movie size={24} />
              </a>
            </div>
            <div className="text-sm">© 2025 Max Ihnen</div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;