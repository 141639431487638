import React from 'react';
import { Code, BrandGithub, ExternalLink } from 'tabler-icons-react';

const Portfolio = () => {
  const projects = [
    {
      title: "Secrete",
      description: "Modern, bilingual Secret Santa web application that simplifies gift exchange organization. Features include participant restrictions, price range voting, and persistent group access.",
      techStack: ["React 18", "Vite", "Python Flask", "MySQL", "Tailwind CSS", "i18next", "Flask-SQLAlchemy"],
      role: "Full Stack Developer",
      highlights: [
        "Implemented bilingual support (Spanish/English)",
        "Built mobile-first, responsive dark mode interface",
        "Developed matching algorithm with custom restrictions",
        "Created UUID-based persistent group access system",
        "Integrated email notification system",
        "Designed price range voting visualization"
      ],
      links: {
        live: "https://secrete.cl",
        github: [
          {
            url: "https://github.com/maxdenuevo/secrete",
            label: "Repository"
          }
        ]
      },
      image: "/api/placeholder/600/400"
    },
    {
      title: "FreelanceHub",
      description: "Full-stack web application for project management, developed as a graduation project for 4Geeks Academy bootcamp.",
      techStack: ["Python Flask", "PostgreSQL", "React.js", "Bootstrap", "JWT", "CORS", "Git", "Vercel"],
      role: "Team Lead & Back-end Developer",
      highlights: [
        "Led development team",
        "Spearheaded back-end development using Python Flask",
        "Implemented dynamic Contract creation module",
        "Managed project timeline and task distribution"
      ],
      links: {
        live: "https://freelancehub.cl",
        github: [
          {
            url: "https://github.com/maxdenuevo/FreelanceHub-front",
            label: "Frontend Repo"
          },
          {
            url: "https://github.com/maxdenuevo/FreelanceHub-front",
            label: "Backend Repo"
          }
        ]
      },
      image: "/api/placeholder/600/400"
    },
    {
      title: "Pholtaic",
      description: "Advanced dashboard for photovoltaic plant management system. Developed comprehensive monitoring and control features for efficient plant operations.",
      techStack: ["AWS", "Python Flask", "PostgreSQL", "FTP", "React", "RESTful API"],
      role: "Full Stack Developer",
      highlights: [
        "Implemented real-time data monitoring system",
        "Developed secure FTP integration for data transfer",
        "Created responsive dashboard interface",
        "Established robust database architecture",
        "Implemented AWS cloud infrastructure"
      ],
      links: {
        github: [],  // Empty for NDA projects
      },
      image: "/api/placeholder/600/400"
    },
    {
      title: "Professional Portfolio Websites",
      description: "Design and development of professional portfolio websites for clients, featuring modern design and optimal performance.",
      techStack: ["React", "Tailwind CSS", "Vercel", "Responsive Design"],
      role: "Frontend Developer",
      highlights: [
        "Created responsive, modern designs",
        "Implemented downloadable CV functionality",
        "Built project showcase sections",
        "Optimized performance and SEO",
        "Integrated investigation/research sections"
      ],
      links: {
        live: "https://jacintadiestre.com",
        github: ["https://github.com/maxdenuevo/jacinta"]
      },
      image: "/api/placeholder/600/400"
    },
    {
      title: "Enterprise Web Scraper",
      description: "Developed an automated, scalable on-demand system for massive public information collection, integrating various data sources and delivering processed data formats.",
      techStack: ["Python", "AWS Lambda", "AWS S3", "AWS SQS", "CI/CD", "Unit Testing"],
      role: "Junior Developer",
      highlights: [
        "Implemented AWS services (S3, SQS, Lambda)",
        "Set up separate test and production environments",
        "Configured anti-scraping proxy service",
        "Established automated CI/CD pipeline",
        "Developed modular programming solution by store/country",
        "Created comprehensive technical documentation",
        "Implemented unit testing"
      ],
      links: {
        github: []  // Empty for enterprise projects
      },
      image: "/api/placeholder/600/400"
    }
  ];

  return (
    <div className="bg-[#285DEF] min-h-screen pt-20 pb-12">
      <div className="container mx-auto px-4 xl:px-8 2xl:px-16">
        <h1 className="text-4xl font-bold text-white mb-8">Projects</h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {projects.map((project, index) => (
            <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden">
              <img 
                src={project.image} 
                alt={project.title} 
                className="w-full h-48 object-cover"
              />
              
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <h3 className="text-2xl font-bold text-gray-800">{project.title}</h3>
                  <div className="flex space-x-3">
                    {project.links.github?.map((repo, i) => (
                      repo.url && (
                        <a key={i}
                           href={repo.url}
                           title={repo.label}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="text-gray-600 hover:text-gray-900">
                          <BrandGithub size={24} />
                        </a>
                      )
                    ))}
                    {project.links.live && (
                      <a href={project.links.live}
                         target="_blank"
                         rel="noopener noreferrer"
                         className="text-gray-600 hover:text-gray-900">
                        <ExternalLink size={24} />
                      </a>
                    )}
                  </div>
                </div>

                <p className="text-gray-600 mb-4">{project.description}</p>

                <div className="mb-4">
                  <h4 className="font-semibold text-gray-800 mb-2">Role</h4>
                  <p className="text-gray-600">{project.role}</p>
                </div>

                <div className="mb-4">
                  <h4 className="font-semibold text-gray-800 mb-2">Key Highlights</h4>
                  <ul className="list-disc list-inside text-gray-600">
                    {project.highlights.map((highlight, i) => (
                      <li key={i}>{highlight}</li>
                    ))}
                  </ul>
                </div>

                <div className="flex flex-wrap gap-2">
                  {project.techStack.map((tech, i) => (
                    <span 
                      key={i}
                      className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                    >
                      <Code size={16} className="mr-1" />
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;